import "@/chipply/ImportBootstrap";
import NavigationTop from "@/components/navigation/NavigationTop.vue";
import store from "@/store/RootStore";
import vuetify from "@/plugins/vuetify";

(window as any).NavigationTop = NavigationTop;
(window as any).VuexStore = store;
(window as any).Vuetify = vuetify;

export default NavigationTop;
